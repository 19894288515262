@use "./colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@100;400;600;700&display=swap');

$header-size: 64px;

#app {
    display: flex;
    flex-direction: column;
    justify-content: center;

    #app-header {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: colors.$tertiary;
        color: colors.$tertiary;
        height: calc(100vh - 1px);
        border-bottom: 1px #fff solid;
        box-shadow: 0px 0px 25px 15px colors.$primary;
        overflow: hidden;

        @media only screen and (min-width: 600px) {
            width: 100%;
        }

        @media only screen and (max-width: 600px) {
            width: 100vw;
        }

        &.hide {
            visibility: hidden;
        }

        .passify-logo {
            position: absolute;
            z-index: 2;
            top: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            opacity: 0.4;

            img {
                border: 0;

                @media only screen and (min-width: 600px) {
                    width: 70px;
                }
        
                @media only screen and (max-width: 600px) {
                    width: 60px;
                }
            }
        }

        video {
            height: 100vh;
            transition: all 0.75s linear;

            &.reverse {
                transform: scaleX(-1);
            }

            &.video-desktop {
                aspect-ratio: 16 / 9;
            }

            &.video-mobile {
                aspect-ratio: 9 / 16;
            }

            @media only screen and (min-width: 600px) {
                &.video-desktop {
                    display: block;
                }

                &.video-mobile {
                    display: none;
                }
            }

            @media only screen and (max-width: 600px) {
                &.video-desktop {
                    display: none;
                }

                &.video-mobile {
                    display: block;
                }
            }
        }

        .cta-container {
            position: absolute; 
            top: 0px;
            left: 0px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;

            button {
                border: 1px solid colors.$primary;
                border-radius: 0px;
                background-color: transparent;
                color: colors.$primary;
                box-shadow: 0px 0px 25px 5px colors.$primary;
                font-weight: bold;

                @media only screen and (min-width: 600px) {
                    margin-top: 70vh;
                }
        
                @media only screen and (max-width: 600px) {
                    margin-top: 50vh;
                }

                &.hide {
                    display: none;
                }
            }
        }

        #menu {
            position: fixed;
            right: 15px;
            top: 15px;
            width: 50px;
            text-align: right;

            &.hide {
                display: none;
            }

            button {
                color: #fff;
                font-size: 24px;
            }
        }
    }

    #app-body {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        //max-width: 480px;
        width: 100%;
        padding-top: 45px;

        @media only screen and (min-width: 600px) {
            min-height:  calc(100vh - 45px);
        }

        @media only screen and (max-width: 600px) {
            min-height: calc(100vh - 110px);
        }

        &.hide {
            display: none;
        }

        & > div {
            width: 100%;
            max-width: 480px;
            padding-left: 12px;
            padding-right: 12px;
        }

        #page-bottom {
            width: 100%;
            height: 1px;
        }
    }

    #app-menu {
        position: fixed;
        top: 0px;
        left: -100vw;
        z-index: 4;
        width: 0vw;
        height: 100vh;
        background-color: colors.$tertiary;
        overflow: hidden;
        transition: width 0.2s linear, left 0.2s linear;

        &.active {
            left: 0vw;
            width: 100vw;
            //height: 100vh;
            transition: width 0.2s linear, left 0.2s linear;
        }

        .button-container {
            margin-top: 10px;
            margin-right: 15px;
            width: calc(100vw - 15px);
            text-align: right;

            button {
                color: colors.$primary;
                font-size: 24px;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            margin-top: 12px;
            padding: 0;
            overflow: hidden;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 64px;
                border-top: 1px colors.$primary solid;
                font-size: 20px;
                font-weight: bold;
                cursor: default;
            }
        }

        hr {
            margin: 0px;
            border-bottom: 0px;
            border-color: colors.$primary;
            background-color: colors.$primary;
        }

        .passify-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 24px;
        }
    }
}

#info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .type-cursor {
        font-size: 0.9em;
        width: 10px;
        height: 15px;
        animation: type-cursor-animation 0.5s infinite alternate linear;
    }

    p, h2, h3 {
        width: 100%;
        font-size: 1em;
        font-weight: 400;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 15px;
        text-shadow: 0px 0px 15px colors.$primary, 1px 1px 15px colors.$primary, -1px -1px 15px colors.$primary;
    }

    h2 {
        font-family: 'Orbitron';
        font-weight: bold;
        text-align: center;
        font-size: 2em;
        margin-top: 15px;
        color: colors.$secondary;
        text-shadow: 0 0 10px #fff, 0 0 20px rgba(colors.$primary, 1), 0 0 30px colors.$primary;
        animation: none;
    }

    h3 {
        font-size: 1.5em;
    }

    #claim-ticket {
        width: 80%;
        margin-top: 30px;
        margin-bottom: 45px;
        transition: all 0.2s linear;
    
        p {
            text-align: center;
        }
    
        .form-field-container {
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin-bottom: 12px;
    
            label {
                padding-left: 16px;
                font-size: 13px;
            }
    
            input {
                width: calc(100% - 20px);
                outline: none;
                background-color: colors.$secondary;
                border-color: colors.$primary;
                border-radius: 24px;
                box-shadow: 0px 0px 25px 5px colors.$primary;
            }

            #form-verification-code {
                text-align: center;
            }
        }
    
        .form-button-container {
            display: flex;
            justify-content: center;
            margin-top: 10px;
    
            button.primary {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
                width: 100%;
                border-radius: 24px;
                border-color: colors.$primary;
                background-color: colors.$primary;
                color: #fff;
                box-shadow: 0px 0px 25px 5px colors.$primary;
            }

            button.google {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
                width: 100%;
                border-radius: 24px;
                border: 0px;
                background-color: #fff;
                color: #000;
                box-shadow: 0px 0px 25px 5px colors.$primary;

                img {
                    width: 16px;
                }
            }
        }

        .form-resend {
            margin-top: 10px;
            font-size: 12px;

            a {
                color: #daf;
            }
        }

        .form-confirmed {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;

            .check {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: 24px;
                border: 0px;
                background-color: colors.$primary;
                color: colors.$secondary;
                box-shadow: 0px 0px 25px 5px colors.$primary;
                font-size: 20px;
            }

            a {
                color: #daf;
                font-size: 12px;
            }
        }
    }

    #looks {
        width: 100%;
        margin-bottom: 30px;

        h2 {
            margin-top: 30px;
            margin-bottom: 0px;
        }
    
        #looks-grid {
            display: grid;
            grid-template-columns: calc(calc(100% / 3) - 1px) calc(calc(100% / 3) - 1px) calc(calc(100% / 3) - 1px);
            gap: 1px;
            margin-top: 15px;
            width: 100%;
            transition: height 0.5s linear;
    
            div {
                width: 100%;
                aspect-ratio: 1;
                background-color: #102;
                overflow: hidden;
    
                img {
                    width: 100%;
                    aspect-ratio: 1;
                    object-fit: cover;
                    cursor: pointer;
                }
            }
        }
    
        #show-more-button-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
    
            button {
                border: 1px solid colors.$primary;
                background-color: transparent;
                color: colors.$primary;
                font-size: 12px;
                padding-top: 2px;
                padding-bottom: 2px;
                margin-bottom: 0;
                min-height: 30px;
            }
        }
    
        #selected-picture-container {
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100vw;
            height: 100vh;
    
            &.hide {
                display: none;
            }
    
            .screen-overlay {
                position: fixed;
                top: 0px;
                left: 0px;
                z-index: 1;
                width: 100vw;
                height: 100vh;
                background: rgba(#000, 0.35);
                backdrop-filter: blur(5px);
            }
    
            .img-container {
                z-index: 2;
                max-width: 80vw;
                max-height: 80vh;
    
                .look-items {
                    position: absolute;
                    z-index: 2;
    
                    .look-item {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
    
                        .point {
                            /*border: 3px solid #fff;
                            border-radius: 3px;
                            box-shadow: 1px 1px 15px 3px #000;
                            transition: all 0.05s linear;*/
                            animation: point 0.4s infinite alternate linear;
                        }
    
                        .item-description {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            border-radius: 8px;
                            padding: 5px;
                            white-space: nowrap;
                            background-color: rgba(#000, 0.4);
                            color: #fff;
                            text-shadow: 1px 1px 2px #000;
                            font-size: 10px;
                            cursor: pointer;
    
                            &.q1 {
                                top: 20px;
                                right: calc(50% + 5px);
                            }
    
                            &.q2 {
                                top: 20px;
                                left: calc(50% + 5px);
                            }
    
                            &.q3 {
                                bottom: 20px;
                                left: calc(50% + 5px);
                            }
    
                            &.q4 {
                                bottom: 20px;
                                right: calc(50% + 5px);
                            }
    
                            .title {
                                font-size: 14px;
                            }
                        }
    
                        @media only screen and (min-width: 600px) {
                            .item-description {
                                display: none;
                            }
    
                            &:hover {
                                .item-description {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
                
                img {
                    max-width: 80vw;
                    max-height: 80vh;
                }
            }
    
            .img-navigation {
                position: absolute;
                z-index: 2;
    
                .left-arrow {
                    position: fixed;
                    top: calc(50vh - 20px);
                    left: 0;
                    width: 40px;
                    height: 40px;
                }
    
                .right-arrow {
                    position: fixed;
                    top: calc(50vh - 20px);
                    right: 0;
                    width: 40px;
                    height: 40px;
                }
    
                button {
                    border-radius: 20px;
                    width: 40px;
                    height: 40px;
                    background-color: rgba(#000, 0.4);
                    color: #fff;
                }
    
                @media only screen and (min-width: 600px) {
                    .left-arrow {
                        margin-left: 15px;
                    }
    
                    .right-arrow {
                        margin-right: 15px;
                    }
                }
            }
        }
    }

    #map {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        width: 100%;
        height: 260px;
        margin-top: 15px;
        margin-bottom: 30px;
        overflow: hidden;
        background-image: url("../public/images/map.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;

        .pin-container {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 260px;

            & > div {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 2;
                width: 15px;
                height: 15px;

                /*&:hover {
                    img {
                        transform: scale(1.35);
                    }
                }*/
            }

            #pin1 {
                top: 40%;
                left: 49.2%;
            }

            #pin2 {
                top: 45.5%;
                left: 45%;
            }

            img {
                width: 15px;
                transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
        }
    }

    .passify-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        margin-bottom: 15px;

        img {
            width: 70px;
        }
    }

    #fast-forward-button-container {
        position: fixed;
        bottom: 20px;
        right: 12px;
        z-index: 3;
        width: 32px;
        height: 32px;

        button {
            background-color: rgba(colors.$primary, 0.2);
            color: colors.$primary;
            border: 1px solid colors.$primary;
            width: 32px !important;
            height: 32px !important;
            border-radius: 8px;
            font-size: 14px;
        }
    }
}

@keyframes point {
    from {
        border: 2px solid #fff;
        border-radius: 2px;
        box-shadow: 0px 0px 15px 2px #888;
    }
    to {
        border: 3px solid #fff;
        border-radius: 3px;
        box-shadow: 0px 0px 25px 10px #fff;
    }
}

@keyframes type-cursor-animation {
    from {
        opacity: 0;
        background-color: rgba(colors.$primary, 0);
    }
    to {
        opacity: 1;
        background-color: rgba(colors.$primary, 1);
    }
}

@keyframes neon-text {
    from {
        text-shadow: 0px 0px 15px rgba(colors.$primary, 0.8);
    }
    to {
        text-shadow: 0px 0px 25px rgba(colors.$primary, 1);
    }
}