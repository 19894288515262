@use "./colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&display=swap');

@font-face {
  font-family: 'Matrix';
  src: url('../public/matrix.ttf') format('truetype');
}

body {
  margin: 0;
  color: #fff;
  background-color: colors.$tertiary;
  background-image: url("../public/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: monospace, 'Courier New', Courier, 'Urbanist', 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, div, p, h2, h3 {
  //animation: neon-text 2s infinite alternate linear;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button {
  font-family: 'Urbanist', 'Inter', sans-serif;
  border-radius: 8px;
  padding: 8px;
}

input {
  background-color: #102;
  min-height: 24px;
}

button {
  min-height: 40px;
}

button.primary {
  border: 1px solid colors.$primary;
  background-color: colors.$primary;
  color: colors.$tertiary;
}

input, button.secondary {
  border: 1px solid colors.$primary;
  color: colors.$primary;
}

button.invisible {
  background: transparent;
  border: 0px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-button, ::-webkit-scrollbar-corner {
  background: colors.$tertiary; 
}

::-webkit-scrollbar-thumb {
  background: rgba(colors.$primary, 0.4); 
}

::-webkit-scrollbar-thumb:hover {
  background: colors.$primary; 
}

@keyframes neon-text {
  from {
      text-shadow: 0px 0px 5px rgba(colors.$primary, 0.8), 0px 0px 10px rgba(colors.$primary, 0.8), 0px 0px 15px rgba(colors.$primary, 0.8);
  }
  to {
      text-shadow: 0px 0px 15px rgba(colors.$primary, 1), 0px 0px 25px rgba(colors.$primary, 1), 0px 0px 35px rgba(colors.$primary, 1);
  }
}